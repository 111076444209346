// section SEND DATA
export async function sendData(url, data) {
    let csrf = document.querySelector('meta[name="csrf-token"]').content
    let res = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrf
        }, body: JSON.stringify(data),
    });

    if (res.ok) {
        return await res.json();
    } else {
        // const json = await res.json();
        // console.log('Error:' + json.message);
        // throw json.message;
    }
}


// section GET DATA
export async function getdData(url, data) {
    let csrf = document.querySelector('meta[name="csrf-token"]').content
    let res = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrf
        }, body: JSON.stringify(data),
    });

    if (res.ok) {
        return await res.json();
    } else {
        // const json = await res.json();
        // console.log('Error:' + json.message);
        // throw json.message;
    }
}
