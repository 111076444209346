// Import any dependencies or modules needed for this part of the app
import './../../js/bootstrap.js';
import PQueue from 'p-queue';
import Cookie from 'js-cookie'
import delay from 'delay';
import * as shared from '../../js/shared.js'

window.siteEnv = {
    ...window.siteEnv,
    gaClientIds: [],
    gaClientId: null,
    gaInitialized: false
};
// Create a new queue instance with concurrency set to 1 (adjust as needed)
const queue = new PQueue({concurrency: 1, autoStart: false});
queue.pause();
const queueStandard = new PQueue({concurrency: 1, autoStart: false});
queueStandard.pause();

// JavaScript to toggle mobile menu
document.getElementById('nav-toggle').addEventListener('click', function () {
    let mobileMenu = document.getElementById('mobile-menu');
    if (mobileMenu.classList.contains('hidden')) {
        mobileMenu.classList.remove('hidden');
    } else {
        mobileMenu.classList.add('hidden');
    }
});

// section GET GA CLIENT ID
function getGAClientId() {
    if (window.siteEnv.gaClientId !== null) {
        console.log('getGAClientId exist', window.siteEnv);
        window.siteEnv.gaInitialized = true
        return window.siteEnv.gaClientId;
    }
    console.log('getGAClientId continue', window.siteEnv);

    // Ensure the gtag function is available and GA4 is configured
    if (typeof gtag === 'function') {
        console.log('Getting GA client id')
        gtag('get', window.siteEnv.GA4_ID, 'client_id', function (gaClientId) {
            console.log('Get GA Client ID:', gaClientId)
            storeGA4ClientId(gaClientId);
            // Send GA Client ID to your backend
            setTimeout(() => {

                console.log('GA Client ID:', gaClientId)
                window.siteEnv.gaInitialized = true;
                console.log('GA4 initialized');
                window.siteEnv.gaClientId = gaClientId
                sendGaIdentifierToServer(gaClientId).then(() => {
                    console.log('All done');
                    queue.start();
                    console.log('getting likes');
                    getUserPostLikes()
                })
                    .catch(error => {
                        console.warn('Error sendGaIdentifierToServer:');
                    });
            }, 100);
        });
    } else {
        window.siteEnv.gaInitialized = false
        window.siteEnv.gaClientId = null;
        console.warn('Google Analytics or GTM not initialized yet.');
    }
}

// section GET POSTS LIKES
function getPostsLikes() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-likes';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsLikes' in res.data) {
                        Object.entries(res.data.postsLikes).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-likes`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content
                                setTimeout(() => {
                                    const parentSpan = postElement.parentElement
                                    parentSpan.classList.remove('opacity-0')
                                    parentSpan.classList.add('post-info-fade-in')
                                }, 250);
                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsLikes');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(250))
}

// section GET POSTS VIEWS
function getPostsViews() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-views';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsViews' in res.data) {
                        Object.entries(res.data.postsViews).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-views`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content

                                const parentSpan = postElement.parentElement
                                parentSpan.classList.remove('opacity-0')
                                parentSpan.classList.add('post-info-fade-in')

                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsViews:');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(250))
}


// section GET POSTS DISMIS TIMES
function getPostsDismissTimes() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post-sale');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-dismiss-times';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsDismissTimes' in res.data) {
                        Object.entries(res.data.postsDismissTimes).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-tte`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content

                                const parentSpan = postElement.parentElement
                                parentSpan.classList.remove('opacity-0')
                                parentSpan.classList.add('post-info-fade-in')

                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsViews:');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(250))
}

function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    console.log(value);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


// Function to send GA Client ID to your backend
async function sendGaIdentifierToServer(gaClientId) {
    const url = '/api/store-user-identifier';
    const uuid = Cookie.get('uuid') || localStorage.getItem('uuid');

    const data = {gaClientId: gaClientId, gaClientIds: getGAClientIds(), uuid: uuid}
    const consetGranted = true;

    return shared.sendData(url, data).then(res => {
            if (res.success) {
                if (consetGranted) {
                    if (res.userIdentifier.ga_client_id) {
                        console.log('Setting gaClientIds, userIdentifier')
                        // localStorage.removeItem('gaClientIds');
                        localStorage.setItem('userIdentifier', res.userIdentifier.ga_client_id);
                    }
                    if (uuid) {
                        console.log('Setting uuid')
                        localStorage.setItem('uuid', uuid);
                    }
                }
                console.log(res)
            }
        }
    ).catch(error => {
        console.warn('Failed to store ga_client_id');
        // throw new Error('Response was not successful');
    });
}

// section MAKE LIKED
function makeLiked(element) {
    if (!element) {
        return
    }
    let classes = []
    element.classList.forEach(className => {
        if (className.startsWith('hover:')) {
            element.classList.remove(className);
            let classAfterHover = className.split(':')[1]; // Extract the class after 'hover:'
            if (classAfterHover.startsWith('text-') || classAfterHover.startsWith('bg-')) {
                classes.push(classAfterHover)
            }
        }
        if (className.startsWith('bg-') || className.startsWith('text-sl')) {
            element.classList.remove(className);
        }
    });
    element.classList.add('liked');
    // adnimate only loaded likes
    if (!element.classList.contains('like-loading')) {
        element.classList.add('animate-ping');
        setTimeout(() => {
            element.classList.remove('animate-ping');
        }, 1000);
    }


    element.classList.remove('like-loading');
    element.removeEventListener('click', onLikeClick)

}

// section ON LIKE CLICK
function onLikeClick(event) {
    event.preventDefault(); // Prevent the default anchor behavior

    makeLiked(event.target.closest('.like'))
    // Find the closest parent 'post' div to get the post ID
    const postDiv = this.closest('.post');
    const postId = postDiv.getAttribute('data-post-id');
    const url = '/api/store-post-like';

    // Add to queue
    queue.add(async () => {
        // Wait for ga_client_id to be available before sending
        //data.ga_client_id = await getGAClientId();
        const data = {postId: postId, gaClientId: getGAClientId()}

        // Call shared.sendData after ga_client_id is set
        return shared.sendData(url, data)
            .then(result => {
                console.log('Data sent successfully:', result);
            })
            .catch(error => {
                console.warn('Error sending data:');
            });
    });
}

function makeLikedByIds(idsArray) {
    console.log('makeLikedByIds')
    idsArray.forEach(id => {
        // Find divs with the data-post-id attribute equal to the current ID
        const div = document.querySelector(`div[data-post-id="${id}"]`);

        if (div) {
            // Find children with the .like class within the found div
            const likeChildren = div.querySelectorAll('.like');
            likeChildren.forEach(child => {
                // Call the makeLiked method with each .like child element
                makeLiked(child);
            });
        }
    });
}

// section GET USER POST LIKES
function getUserPostLikes() {
    const url = '/api/get-user-post-likes';
    const data = {gaClientId: getGAClientId()}

    queue.add(async () => {
        shared.getdData(url, data).then(res => {
                if (res.success) {
                    makeLikedByIds(res.data.likedPosts)
                    const likeButtons = document.querySelectorAll('.post .like:not(.liked)');
                    likeButtons.forEach(function (element) {
                        element.addEventListener('click', onLikeClick)
                        element.classList.remove('like-loading');
                    });
                }
            }
        ).catch(error => {
            console.warn('Failed to get getUserPostLikes');
            // throw new Error('Response was not successful');
        });
    });
}

function getGAClientIds() {
    // Retrieve stored IDs from localStorage (if any)
    return JSON.parse(localStorage.getItem('gaClientIds')) || [];
}

// Store GA4 Client ID
function storeGA4ClientId(clientId) {

    let storedIds = getGAClientIds();
    // Add the new client ID only if it doesn't already exist in the array
    if (clientId && !storedIds.includes(clientId)) {
        storedIds.push(clientId);
    }

    // Save the updated array back to localStorage
    localStorage.setItem('gaClientIds', JSON.stringify(storedIds));
}


// Initialize the variable
let ga4ReadyTriggered = false;

// Check if dataLayer is initialized, otherwise create it
window.dataLayer = window.dataLayer || [];

// Override the push method of the dataLayer
let originalPush = window.dataLayer.push;

// Listen for push events to the dataLayer
window.dataLayer.push = function () {
    // Call the original push method to ensure GTM continues working
    originalPush.apply(window.dataLayer, arguments);

    // Check each pushed event
    for (var i = 0; i < arguments.length; i++) {
        if (arguments[i].event === 'ga4Ready') {
            handleGa4Ready(); // Call your handler when the event is found
        }
    }
};

// Event handler for GA4 ready
function handleGa4Ready() {
    ga4ReadyTriggered = true;
    console.log('GA4 is ready X');
    getGAClientId()
}

// Log the initial state before GA4 is ready
console.log('Initial state: ', ga4ReadyTriggered ? 'GA4 ready' : 'GA4 NOT ready');

document.addEventListener('DOMContentLoaded', function () {
    console.log('DOM fully loaded and parsed');
    setTimeout(() => {
        getPostsViews()
        getPostsDismissTimes()
        getPostsLikes()


        queueStandard.start()

    }, 1000);

});
